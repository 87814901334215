/* CountryDetails.css */

/* Grid container for cards */
.grid {
  display: grid;
  gap: 1rem;
}

/* Individual card styling */
.card-container {
  display: flex;
  justify-content: center;
}

/* Card styling */
.card {
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease;
  cursor: pointer;
  position: relative;
}

/* Card image styling */
.card-image {
  width: 100%;
  height: 200px; /* Adjust the height of the image */
  overflow: hidden;
}

.card-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

/* Card text styling */
.card-text {
  padding: 1rem;
}

/* Hover effect for card */
.card:hover {
  transform: scale(1.05);
}
