.container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
  }
  
  .container h1 {
    font-size: 2em;
    margin-bottom: 0.5em;
  }
  
  .container h2 {
    font-size: 1.5em;
    margin-top: 1em;
    margin-bottom: 0.5em;
  }
  
  .container p, ul {
    margin-bottom: 1em;
  }
  
  .container ul {
    padding-left: 20px;
  }
  
  .container a {
    color: blue;
    text-decoration: underline;
  }
  