/* App.css */

.App {
    text-align: center;
    font-family: Arial, sans-serif;
  }
  
  .App-header {
    background-color: #282c34;
    padding: 20px;
    color: white;
  }
  
  h1 {
    font-size: 2.5rem;
    margin: 20px 0;
  }
  
  .gallery-container {
    width: 80%;
    margin: 5% auto;
    padding: 20px 0;
  }
  
  .gallery-image {
    width: 100%;
    height: 500px;
    object-fit: cover;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
